import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="243" height="9" viewBox="0 0 243 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.0096 8.33102C40.0135 7.42759 3.38171 8.25375 0.711701 7.99818C-1.47285 7.64156 1.8849 1.47805 3.88741 0.568684C4.59537 0.24773 5.42466 0.18235 7.32603 0.354714C8.84308 0.485473 19.2804 0.467639 29.3537 0.402259C39.4269 0.33688 49.136 0.259614 49.136 0.259614C49.6417 0.455753 51.1992 -0.180211 51.4824 0.0515895C51.9274 0.324995 67.8665 0.372544 84.3721 0.372544C100.878 0.372544 117.95 0.354711 120.62 0.360654C123.876 0.366598 121.267 0.717274 125.11 0.610289C133.282 0.384432 151.042 0.883691 157.231 0.616229C162.207 0.40226 163.259 0.414147 167.992 0.681609C172.806 0.955015 198.616 0.996621 201.61 0.622174C202.338 0.515189 202.884 0.562737 202.884 0.699439C203.228 0.71727 221.028 1.11549 221.089 1.21653C221.21 1.35324 221.756 1.4305 222.282 1.34135C223.253 1.21059 242.651 1.01445 242.894 1.41862C243.642 2.57762 240.264 7.38004 237.898 8.5331C235.41 9.75154 223.091 8.12894 212.897 8.73518C157.292 8.00412 168.356 8.06356 155.795 8.50338C154.682 8.07544 150.192 9.04425 147.522 8.29536C146.43 7.98629 138.44 7.86742 136.619 8.14677C134.617 8.45584 94.5869 7.30277 89.8942 8.17054C82.4505 7.40976 52.0487 8.19432 45.0096 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="101" height="9" viewBox="0 0 101 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7077 8.33102C16.6311 7.42759 1.40557 8.25375 0.29581 7.99818C-0.612174 7.64156 0.783437 1.47805 1.61576 0.568684C1.91001 0.24773 2.25469 0.18235 3.04497 0.354714C3.67552 0.485473 8.01368 0.467639 12.2005 0.402259C16.3873 0.33688 20.4228 0.259614 20.4228 0.259614C20.633 0.455753 21.2803 -0.180211 21.398 0.0515895C21.583 0.324995 28.2079 0.372544 35.0682 0.372544C41.9285 0.372544 49.0243 0.354711 50.134 0.360654C51.4876 0.366598 50.4031 0.717274 52.0005 0.610289C55.397 0.384432 62.7786 0.883691 65.3512 0.616229C67.4194 0.40226 67.8566 0.414147 69.8239 0.681609C71.8248 0.955015 82.5524 0.996621 83.7967 0.622174C84.0994 0.515189 84.3264 0.562737 84.3264 0.699439C84.4693 0.71727 91.8677 1.11549 91.8929 1.21653C91.9434 1.35324 92.1704 1.4305 92.3889 1.34135C92.7925 1.21059 100.855 1.01445 100.956 1.41862C101.267 2.57762 99.863 7.38004 98.8793 8.5331C97.8453 9.75154 92.7252 8.12894 88.488 8.73518C65.3764 8.00412 69.9752 8.06356 64.7543 8.50338C64.2919 8.07544 62.4254 9.04425 61.3157 8.29536C60.8617 7.98629 57.5408 7.86742 56.7842 8.14677C55.9519 8.45584 39.3139 7.30277 37.3634 8.17054C34.2695 7.40976 21.6334 8.19432 18.7077 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)