/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/sip-trunk.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/toip.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageSipTrunk/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageSipTrunk/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageSipTrunk/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageSipTrunk/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageSipTrunk/images/accordionLeft/imageFive.webp"


import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSipTrunk.jpg"

import { TitleUnderlineSipTrunkOne, TitleUnderlineSipTrunkTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineSipTrunk"
import imageStartSipTrunk from "@components/pageFunctionalities/components/intro/images/imageStartSipTrunk.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageTwo.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

const titleStart = (
  <p>
    Escala y mejora la comunicación de tus llamadas con nuestro <TitleUnderlineSipTrunkOne underline="SIP Trunk" /> <TitleUnderlineSipTrunkTwo underline="Software" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Despliega rápidamente campañas de voz inbound y outbound hacia cualquier parte del mundo y automatízalas con IVRs con inteligencia artificial, notificaciones automáticas y marcadores inteligentes.
        <br /><br />
        Llama desde cualquier lugar, con cualquier dispositivo y paga solo por lo que consumes. Mejora la productividad de tus agentes y el resultado de tus servicios.
      </p>
    ),
    image: imageStartSipTrunk,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "SIP Trunk: Qué es y su importancia en tu Contac Center",
      textbody:
        "¿Quieres saber todo sobre el SIP Trunk? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/sip-trunk/",
      card: true,
    },
    {
      image: article2,
        title: "14 preguntas para elegir el mejor software de Contact Center",
        textbody:
          "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
        link: "https://beexcc.com/blog/mejor-software-call-center/",
        card: true,
      },
    {
      image: article3,
      title: "ToIP: Qué es, ventajas y en qué se diferencia vs VoIP",
      textbody:
        "¿Quieres saber todo sobre el ToIP? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/toip/",
    },
    {
      image: article4,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
    },
    {
      image: article5,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
  ],
  contacts: {
    title: "¡Traslada tu centro de llamadas a la nube con nuestra SIP Trunk!",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Llama a todo el mundo",
      description: "<p>Expande el alcance de tus campañas y obtén la capacidad de marcar y recibir llamadas internacionales. Además, personaliza el identificador de llamadas y controla el flujo de llamadas entrantes y salientes.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "IVR + IA",
      description: "<p>Agiliza tu servicio al cliente con un autoservicio inteligente que entiende y resuelve consultas automáticamente, reduciendo tiempos de espera y liberando a tu equipo para tareas de mayor valor, mejorando así la satisfacción del cliente y la productividad.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones con terceros",
      description: "<p>Conecta tu canal telefónico con herramientas externas Salesforce, HubSpot, SAP, Zoho u otros. De este modo, tendrás una visión 360 grados del cliente, asegurando que cada llamada se maneje con toda la información relevante al alcance.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Notificaciones telefónicas",
      description: "<p>Mantén a tus clientes informados con notificaciones automáticas para recordatorios de pago, citas, y alertas de seguridad. Reduciendo cargas administrativas y permitiendo que el equipo se concentre en tareas de mayor valor.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Escala tus operaciones",
      description: "<p>Aumenta o disminuye la capacidad de tus líneas telefónicas según las necesidades de tu empresa, sin la necesidad de infraestructura física adicional. Esto es crucial para gestionar las fluctuaciones en el volumen de llamadas y para el crecimiento a largo plazo de la empresa.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "Llamadas VoIP: ¿Cómo mejorar su rendimiento?",
    description: "<p>Aprende cómo garantizar una calidad de sonido excepcional, minimizar las interrupciones y maximizar la eficiencia de tus llamadas.<br /><br />Nuestro VoIP specialist, Julio Guarniz, te proporciona valiosos consejos para mejorar el rendimiento de tus llamadas con esta tecnología.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Customer Experience",
      title: "Atención al cliente",
      img: televentas,
      link: "/atencion-al-cliente/",
      text:
        "Ofrece una experiencia automatizada con IVRs. Además, intégralo con tu CRM para que puedas personalizar la atención y actualizar los datos post llamada en simples pasos.",
      colorTab: "#FFD96E",
      background: "#ffebb7"
    },
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Utiliza marcadores inteligentes que permitan a tus agentes concentrarse en lo que mejor saben hacer: vender. Analiza y supervisa en tiempo real si tus campañas son efectivas.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Eleva tu proceso de cobranzas con notificaciones automáticas de pago, reduciendo la carga manual y mejorando la eficiencia.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
}

export default data
